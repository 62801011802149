import React from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"
import HelmetTitle from "../components/utils"
import Openings from "../components/Career/Openings"
import CareerBanner from "../components/Career/CareerBanner"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-career.scss"

const Careers = ({ data }) => {
  const { edges: careers } = data.allMdx
  return (
    <Layout>
      <div className="career mb-6">
        <HelmetTitle title="Career" />
        <CareerBanner />
        <div className="w-100 mt-4">
          <h2 className="font-weight-bold py-3 text-center openings">Current Openings</h2>
        </div>
        <Container fluid>
          <Row>
            <Col xl={{ size: 10, offset: 1 }} lg={12} className="my-5">
              {careers.map(({ node: career }) => (
                <Openings
                  key={career.id}
                  title={career.frontmatter.title}
                  link={`/careers${career.frontmatter.slug}#${career.id}`}
                />
              ))}
              <div className="line2" />
              <div className="line mb-4 mt-1" />
            </Col>
          </Row>
        </Container>
        <div />
        <div className="map-banner pt-6 pb-6 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 4, offset: 7 }} lg={{ size: 6, offset: 6 }} md={12}>
                <h1 className="mb-5 font-weight-bold">You, anywhere </h1>
                <h3 className="mb-5 text-justify">
                  Foretheta is a remote-first company. This means that our remote employees, from all over the world,
                  are first-class team members and do not have to deal with teams centralized around a particular city.
                </h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="w-100 mt-4">
          <h2 className="font-weight-bold py-3 text-center">
            <span className="equal-emp">Equal employment</span>
            <span className="opportunity"> Opportunity</span>
          </h2>
        </div>
        <Container fluid>
          <Row>
            <Col xl={{ size: 10, offset: 1 }} lg={12}>
              <p className="text-justify">
                Diversity is important to Foretheta. We are dedicated to providing equal employment opportunity to all
                applicants without regard to race, color, religion, sex, nationality, age, disability, or genetics.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareersIndex {
    allMdx(filter: { fileAbsolutePath: { regex: "/careers/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`

export default Careers

import React from "react"
import { Button } from "reactstrap"
import { Link } from "gatsby"

const Openings = ({ title, link }) => {
  const buttonText = "More Info"
  return (
    <div>
      <div className="line2" />
      <div className="line mt-1" />
      <div className="positions-div mt-3">
        <p className="font-weight-bold opening-title pt-2">{title}</p>
        <Link to={link}>
          <Button>{buttonText}</Button>
        </Link>
      </div>
    </div>
  )
}

export default Openings
